@import 'src/Styles/V2/Constants.scss';
@import 'src/Styles/Utils';


input[type="file"] {
  display: none;
}

.label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  cursor: pointer;
  color: $accentDefault;
  background-color: transparent;

  margin: 0;

  padding: $padding-xs $padding-s * 3.5;
  border-radius: 100px;
  border: 2px solid $accentDefault;

  @include transition;

  &:hover {
    background-color: $accentDefault;
    color: $fgAccent;

    > div > svg > * {
      fill: #FFF;
    }
  }
}

.loader {
  @include transition;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 0;
  overflow: hidden;

  &.visible {
    width: 20px;
    overflow: visible;
  }
}

