:root {
  --toastify-color-success: #5DC389;
  --toastify-color-error: #FB5151;
  --toastify-text-color-success: #fff;
  --toastify-text-color-error: #fff;
}

.Toastify__toast-container {
  width: 80vw;
}

.Toastify__toast {
  height: 53px;
  border-radius: 24px;
  padding: 16px;
  font-family: Work Sans;

}
