@import 'src/Styles/V2/Constants.scss';

.agreementArea {
  border-radius: 10px;
  max-height: 400px;
  white-space: pre-wrap;
  overflow: scroll;
  border: $borderWidth solid $borderColor;
  padding: $padding-xs;
  width: 100%;
  @include input-normal
}

