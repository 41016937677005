@import '../Styles/V2/Constants';

.container {
  min-height: 100svh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.content {
  display: flex;
  justify-content: center;
  padding: 80px 40px;
}
