@import '../Styles/V2/Constants';

.container {
  display: flex;
  justify-content: center;

  > div {
    width: 100%;
    padding: $padding-xl $padding-xxl;

    display: flex;
    flex-direction: column;

    gap: $gap-xs;

    > hr {
      width: 100%;
      border-top-width: 0;
      margin: 0;
      max-width: 100%;
      color: $bgMuted!important;
      border-color: $bgMuted!important;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr fit-content(100%);
  gap: $gap-m;
}
