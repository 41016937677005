@import 'src/Styles/V2/Constants.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.remove {
  cursor: pointer;
}


.imageGrid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-template-rows: repeat(auto-fill, 200px);
}

.imageContainer {
  border-radius: 10px;
  border: 1px solid $borderColor;
  cursor: pointer;
  position: relative;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;

  > img {
    object-fit: contain;
    border-radius: 10px;
    height: calc(200px - 2px);
    width: 100%;
  }
}

.imageName {
  padding-top: 15px;
}

