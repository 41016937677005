@import '../Styles/V2/Constants';

.headline {
    @include headline-2-bold; // There is no regular version of this

    &.bold {
        @include headline-2-bold;
    }
}

.lead {
    @include lead-bold; // There is no regular version of this

    &.bold {
        @include lead-bold;
    }
}

.body {

    &.regular {
        @include body-regular;
    }

    &.bold {
        @include body-bold;
    }
}

.caption {

    &.regular {
        @include caption-regular;
    }

    &.bold {
        @include caption-bold;
    }
}

.small {
    &.regular {
        @include small-regular;
    }

    &.bold {
        @include small-bold;
    }
}

.xsmall {
    &.regular {
        @include xs-regular;
    }

    &.bold {
        @include xs-bold;
    }
}

.buttonlarge {
    &.regular {
        @include button-large-regular;
    }

    &.bold {
        @include button-large-bold;
    }
}

.button {
    @include button-normal-bold; // There is no regular version of this
}

.default {
    color: $fgSubtle;
}

.primary {
    color: $accentDefault;
}

.secondary {
    color: $fgDefault;
}

.error {
    color: $errorMuted;
}

.white {
    color: $fgAccent;
}

.muted {
    color: $fgMuted;
}
