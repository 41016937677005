@import '../Styles/V2/Constants';


.table {
  width: 100%;
  text-align: left;
  border-collapse: separate;

  > thead > tr > th {
    @include caption-bold;
    color: $fgDefault;
  }

  > * > tr > * {
    @include font-work-sans;
    color: $fgDefault;

    height: 50px;

    border-bottom: 1px solid $accentSubtle;
    border-right-width: 0;
  }
}

