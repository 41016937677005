@import '/src/Styles/V2/Constants';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.headline {
  padding-top: $padding-l;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: $padding-s;
}

.tooltip {
  background-color: #0D2019;
  color: $accentDisabled;
  border-radius: 8px;
  padding: $padding-s $padding-m;
  max-width: 250px;

  @include caption-regular;
}
