@import "src/Styles/V2/Constants";

.content {
  padding: $padding-l;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.progressbar {
  max-width: 700px;
  width: 100%;
  height: 16px;
  background-color: $bgSubtle;
  border-radius: 100px;
  overflow: hidden;
}

.progress {
  background-color: $successMuted;
  width: 0%;
  height: 100%;

  transition: width 0.3s;
}

.resourceItem {
  display: flex;
  gap: $padding-l;
  padding: $padding-m 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: $accentDisabled;
}
