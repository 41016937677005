@import '../Styles/V2/Constants';

.checkboxRoot {
  all: unset;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: transparent;
  border: $borderWidth solid $fgMuted;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.error {
    border-color: $errorMuted;
  }
}

.checkboxIndicator {
  color: $fgMuted;
  cursor: pointer;
}

.label {
  padding-bottom: 0;
  cursor: pointer;
}
