@import '../Styles/V2/Constants';

.input {
  width: 100%;
  outline: none;

  @include input-normal;
  border-radius: 6px;
  border: $borderWidth $inputBorder solid;
  padding: $padding-xs calc($padding-xs * 1.5);

  &::placeholder {
    color: $inputPlaceholder;
  }

  &.errors {
    border-color: red;
  }
}
