@import '../Styles/V2/Constants';


.moreToggle {
  border-radius: 24px;
  height: 45px;
  width: 88px;
  border: $borderWidth-s solid $accentEmphasis;
  padding: $padding-xs $padding-s;
}
