@import '../../../../Styles/V2/Constants';

.content {
  display: flex;
  flex-direction: column;
  gap: $gap-s;
  min-width: 400px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-l;

  > * {
    max-width: 840px;
  }
}

.headline {
  padding-top: $padding-l;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: $padding-s;
}

.explainerItem {
  gap: $padding-xs;
  display: grid;
  grid-template-columns: 24px 1fr;

  > span {
    padding-top: 2px;
  }
}
