@import '../Styles/V2/Constants';

.item {
  width: 100%;

  padding: $padding-xs $padding-s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;

  &:hover {
    background-color: $infoSubtle;
  }
}
