@import '../Styles/V2/Constants';

.item {
  margin-right: $padding-l;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $padding-xs;
  color: $fgDefault;
  font-family: "Work Sans", sans-serif;

  text-decoration: none;
  border-bottom: $borderWidth solid transparent;
  padding-bottom: $padding-xs;

  &.active {
    border-bottom: $borderWidth solid $accentDefault;

    > svg > * {
      fill: $accentDefault;
    }
  }
}
