@import '../Styles/V2/Constants';

.box {
  background-color: $bgSurface;
  border-radius: 24px;

  &:not(.noPadding) {
    padding: $padding-xl;
  }

  @include shadow60;

  &.booker {
    background-color: #FFFFBC;
  }

  &.info {
     background-color: #DEC3EF;
  }

  &.small {
    padding: $padding-m;
  }
}
