@import '../../../Styles/V2/Constants';

.container {
  display: flex;

  flex-direction: column;
  gap: $padding-m;
}

.grid {
  padding: $padding-xl 0;
}
