@import 'src/Styles/V2/Constants';

.section {
  padding: 50px 0;
  border: solid $bgMenu;
  border-width: 0 0 1px 0;
}

.padding {
  margin-bottom: 40px;
}

.headLine {
  font-size: 40px;
}

.details > div {

  > * {
    display: inline-block;
    width: 150px;
    padding: 5px 0 ;
  }

  > :first-child {
    font-weight: 600;
    padding-right: 20px;
  }
}

.missingPeriod {
  text-align: start;
  font-weight: bold;
  padding: 5px 0;
  color: $errorEmphasis;
}
