@import '../Styles/V2/Constants';

.space {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: $padding-m;

  &.small {
    gap: $padding-s;
  }

  &.large {
    gap: $padding-l;
  }
}
